@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
 Description:  BAH Engineering Consultancy
 Author:       Hassan A. Adeola
 Author URI:   https://www.hassanadeola.com/
 Template:     NextJS App
 Version:      2.0.0
*/
/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: #fff;
  font-weight: 600;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* 
10. Use Smooth Scrolling
*/
html {
  scroll-behavior: smooth;
}

.menu__menu .dropdown {
  margin-right: -10rem;
  margin-top: 2rem;
  display: none;
  transition: all 2500ms;
}
.menu__menu button svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.15rem;
  transition: transform 500ms;
}
.menu__menu button:hover svg {
  transform: rotate(180deg);
}

.menu__menu:hover .dropdown, .menu__menu:focus .dropdown, .menu__menu:focus-within .dropdown {
  margin-top: 0rem;
  display: block;
}
.menu__menu:has(.menu__menu:focus) {
  margin-top: 0rem;
  display: block;
}

.nav__submenu {
  width: 60rem;
}

.nav__submenu--icon {
  font-size: 3rem;
}

.footer__social svg {
  width: 2rem;
  height: 2rem;
}

.right__aside,
.right__article {
  transition: all 5s;
}

.left__aside,
.left__article {
  transition: all 5s;
}

.hidden__element {
  opacity: 0;
  translate: -100%;
  filter: blur(5px);
}

.show__element {
  opacity: 1;
  filter: blur(0);
  translate: 0;
}

.footer__quick__links li {
  margin-bottom: 0.25rem;
}

.scrollMe svg {
  width: 1.5rem;
  height: 1.5rem;
}

.testimony__section svg {
  font-size: 4em;
  margin-bottom: 0.2em;
}

.image__container {
  position: relative;
  width: 100%;
}
.image__container .image__image {
  display: block;
  width: 100%;
  height: auto;
}
.image__container .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
}
.image__container .service__overlay {
  background-color: #38bdf8;
}
.image__container .product__overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.image__container:hover .overlay {
  width: 100%;
}
.image__container .client__overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  padding: 2em;
  text-align: center;
}
.image__container:hover .client__overlay {
  opacity: 1;
}
.image__container .image__text {
  white-space: nowrap;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* Contact Us page */
.fast__contact svg {
  width: 4rem;
  height: 4rem;
}
.fast__contact li {
  margin-bottom: 0.5rem;
}

.contact__form label {
  text-transform: capitalize;
}
.contact__form small {
  color: tomato;
}

.btn__submit svg {
  margin-top: 0.2rem;
}
.btn__submit .loader svg {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.error__section {
  background-image: url("https://bahengineeringconsultant.com/images/404-light.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

@media only screen and (max-width: 768px) {
  .dropdown .nav__submenu {
    width: 35rem;
  }
  .error__section {
    background-attachment: fixed;
  }
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body,
  .scrollMe {
    color: white;
    background: #121212;
  }
  .theme__section, .theme__nav {
    color: white;
    background: #121212;
  }
  .theme__section a, .theme__nav a {
    color: white;
  }
  .theme .contact__box {
    border: 0.1em solid gray;
    border-radius: 0.5em;
  }
  .theme .contact__box input,
  .theme .contact__box textarea {
    background: transparent;
  }
  .theme .contact__box button {
    background: #121212;
    border: 0.1em solid white;
  }
  .scrollMe {
    border: 0.1em solid gray;
    box-shadow: 1px 1px 2px white;
  }
  .dropdown {
    background-color: rgb(31, 41, 55);
  }
  .image__container .client__overlay {
    background: #121212;
  }
  .error__section {
    background-image: url("https://bahengineeringconsultant.com/images/404-dark.png");
  }
}
@media (prefers-reduced-motion) {
  .hidden__element {
    transition: none;
  }
}/*# sourceMappingURL=style.css.map */