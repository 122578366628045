@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body,
  .scrollMe {
    color: white;
    background: $dark-mode;
  }

  .theme {
    &__section,
    &__nav {
      color: white;
      background: $dark-mode;
      a {
        color: white;
      }
    }
    .contact__box {
      border: 0.1em solid gray;
      border-radius: 0.5em;
      input,
      textarea {
        background: transparent;
      }
      button {
        background: $dark-mode;
        border: 0.1em solid white;
      }
    }
  }
  .scrollMe {
    border: 0.1em solid gray;
    box-shadow: 1px 1px 2px white;
  }
  .dropdown {
    background-color: rgb(31 41 55);
  }
  .image__container {
    .client__overlay {
      background: $dark-mode;
    }
  }
  .error__section {
    background-image: url("https://bahengineeringconsultant.com/images/404-dark.png");
  }
}

@media (prefers-reduced-motion) {
  .hidden__element {
    transition: none;
  }
}
