@tailwind base;
@tailwind components;
@tailwind utilities;

@import "default.scss";

.menu__menu {
  .dropdown {
    margin-right: -10rem;
    margin-top: 2rem;
    display: none;
    transition: all 2500ms;
  }
  button {
    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-top: 0.15rem;
      transition: transform 500ms;
    }
    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.menu__menu {
  &:hover,
  &:focus,
  &:focus-within {
    .dropdown {
      margin-top: 0rem;
      display: block;
    }
  }
  &:has(.menu__menu:focus) {
    margin-top: 0rem;
    display: block;
  }
}

.nav__submenu {
  width: 60rem;
}

.nav__submenu--icon {
  font-size: 3rem;
}

.footer__social {
  svg {
    width: 2rem;
    height: 2rem;
  }
}

.right__aside,
.right__article {
  transition: all 5s;
}

.left__aside,
.left__article {
  transition: all 5s;
}

.hidden__element {
  opacity: 0;
  translate: -100%;
  filter: blur(5px);
}

.show__element {
  opacity: 1;
  filter: blur(0);
  translate: 0;
}

.footer__quick__links li {
  margin-bottom: 0.25rem;
}

.scrollMe {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.testimony__section {
  svg {
    font-size: 4em;
    margin-bottom: 0.2em;
  }
}

.image {
  &__container {
    position: relative;
    width: 100%;
    .image__image {
      display: block;
      width: 100%;
      height: auto;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      width: 0;
      height: 100%;
      transition: 0.5s ease;
    }
    .service__overlay {
      background-color: $primary;
    }

    .product__overlay {
      background-color: $off-black;
    }
    &:hover .overlay {
      width: 100%;
    }
    .client__overlay {
      position: absolute;
      bottom: 0;
      background: $off-black;
      width: 100%;
      transition: 0.5s ease;
      opacity: 0;
      padding: 2em;
      text-align: center;
    }
    &:hover .client__overlay {
      opacity: 1;
    }
    .image__text {
      white-space: nowrap;
      color: white;
      font-size: 1.5em;
      font-weight: 700;
      position: absolute;
      overflow: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}

/* Contact Us page */
.fast__contact {
  svg {
    width: 4rem;
    height: 4rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
}

.contact__form {
  label {
    text-transform: capitalize;
  }
  small {
    color: tomato;
  }
}

.btn__submit {
  svg {
    margin-top: 0.2rem;
  }
  .loader svg {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.error__section {
  background-image: url("https://bahengineeringconsultant.com/images/404-light.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

@import "responsive.scss";
@import "theme.scss";
