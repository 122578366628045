@media only screen and (max-width: 768px) {
  .dropdown {
    .nav__submenu {
      width: 35rem;
    }
  }
  .error__section {
    background-attachment: fixed;
  }
}
