/*
 Description:  BAH Engineering Consultancy
 Author:       Hassan A. Adeola
 Author URI:   https://www.hassanadeola.com/
 Template:     NextJS App
 Version:      2.0.0
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "color.scss";

/*
  1. Use a more-intuitive box-sizing model.
*/
* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #fff;
  font-weight: 600;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  color: rgba($color: #000000, $alpha: 0.8);
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* 
10. Use Smooth Scrolling
*/
html {
  scroll-behavior: smooth;
}
